import React, { useState, useEffect } from "react";
import { Drawer, Button, Checkbox, DatePicker, Input, Space, Select } from "antd";
import filters from "../../assets/images/viewFilter.svg";
import { getFilterDropdownData, getsearchFieldData,getTabData } from "../../services/generic";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import useDebounce from "../../lib/hooks/useDebounce";
import Scrollbars from "react-custom-scrollbars";
import FilterSelector from "../window/filterSelector";
import { useWindowContext } from "../../lib/storage";

const { RangePicker } = DatePicker;
const { Option } = Select;

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

const ViewFilters = ({ columnsData, headerTabData, setFilterArray,tabRecordsResponse, setUpsertFlag }) => {
    const { parentTabRecordData } = headerTabData;
    const [visible, setVisible] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
     const [json, setJson] = useState();
      const { windowStore } = useWindowContext();
      const windowDefinition = { ...windowStore.windowDefinition };
    const { windowId } = useParams();
    const { recordId } = useParams();

    useEffect( async() => {
        const localWindowDefinition = { ...windowDefinition };
        if (localWindowDefinition.tabs) {
          const headerTab = localWindowDefinition.tabs[localWindowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
          const masterParentTabDataIndex = localWindowDefinition.tabs.findIndex(tab => tab.tablevel === "0");
          const localIndex = localWindowDefinition.tabs.findIndex((ta) => ta.ad_tab_id === headerTabData.parent_tab_id);
         
          let sessionValues = {};
          if (localIndex !== -1) {
            const parentTab = localWindowDefinition.tabs[localIndex];
            const getTabDataServerResponse = await getTabData({ windowId:windowDefinition.ad_window_id,ad_tab_id: headerTab.ad_tab_id, recordId: recordId, startRow: "0", endRow: "1" });
            let headerRecordData = getTabDataServerResponse[0];
            parentTab.fields.map((field) => {
              if (field.issession === "Y") {
                if (parentTab.tablevel === "0") {
                  sessionValues[field.column_name] = headerRecordData[field.ad_field_id];
                } else {
                  sessionValues[field.column_name] = parentTabRecordData[field.ad_field_id];
                }
              }
              return null;
            });
            if (parentTab.tablevel !== "0") {
              localWindowDefinition.tabs[masterParentTabDataIndex].fields.map((field) => {
                if (field.issession === "Y") {
                  sessionValues[field.column_name] = headerRecordData[field.ad_field_id];
                }
                return null;
              });
            }
          } else {
            headerTabData?.fields?.map((field) => {
            
              if (field.issession === "Y") {
                  sessionValues[field.column_name] = tabRecordsResponse.length>0?tabRecordsResponse[0][field.ad_field_id]:null;
                }
              return null;
            });
          };
          if (Object.keys(sessionValues).length !== 0) {
            const stringifiedSession = JSON.stringify(sessionValues);
            const updatedSession = stringifiedSession.replace(/\\"/g, '\\"');
            const stringRequest = JSON.stringify(updatedSession);
            setJson(stringRequest);
          } else {
            setJson(null);
          };
        }
      }, [visible]); 


    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    const handleInputChange = (value, columnId) => {
        setSelectedFilters((prev) => ({ ...prev, [columnId]: value }));
    };

    const handleCheckboxChange = (checked, columnId, key) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [columnId]: checked ? key : null,
        }));
    };

    const handleDateRangeChange = (dates, columnId) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [columnId]: dates
                ? [dates[0].format("YYYY-MM-DD"), dates[1].format("YYYY-MM-DD")]
                : [],
        }));
    };

    const handleReset = () => {
        setSelectedFilters({}); // Clear the selected filters
        setFilterArray({}); // Clear the filter array passed from parent
        setOptions([]); // Clear any dropdown options if applicable
        console.log("Filters have been reset!");
    };
    
    const [fieldId, setFieldId] = useState("")
    const [searchKey, setSearchKey] = useState();
    const debouncedSearchKey = useDebounce(searchKey, 350);

    useEffect(() => {
        if (debouncedSearchKey || debouncedSearchKey !== "") {
            fetchSearchOptions(debouncedSearchKey, fieldId);
        }
    }, [debouncedSearchKey]);

    const onSearch = (value, fieldId) => {
        setSearchKey(value);
        setFieldId(fieldId)
        console.log("Searching for:", value, "Field ID:", fieldId);
    };

    const focusSearch = (fieldId) => {
        setSearchKey(""); 
        fetchSearchOptions("", fieldId);
    };

    const fetchSearchOptions = (searchText, fieldId) => {
        setLoading(true);
        let dependent = null;
        getFilterDropdownData(windowId,headerTabData.ad_tab_id, fieldId, searchText,json)
            .then((response) => {
                // console.log( JSON.parse(response).searchData)
                const searchResponseData = JSON.parse(response).searchData

            if (searchResponseData) {
              const searchData = searchResponseData;
              setOptions(searchData);
            } else {
              // Handle the case when the response is not as expected
              console.error("Invalid or unexpected response data:");
            }
                
            })
            .catch((error) => {
                console.error("Error fetching search data:", error);
                setOptions([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    // Utility function to extract URL and name
    const extractUrlAndName = (nameString) => {
        const parts = nameString.split(" - ", 3);
        const url = parts[0]?.startsWith("http") ? parts[0] : null;
        const name = url ? parts[2] || parts[1] : nameString;
        return { url, name };
    };

    const handleSelect = (selectedValue, field_id) => {
        // console.log("Selected Value:", selectedValue);
        // console.log("Field ID:", field_id);

        // Update state or handle the selected value as needed
        setSelectedFilters((prev) => ({
            ...prev,
            [field_id]: selectedValue,
        }));
    };

console.log(columnsData)
    return (
        <>
            {/* Filter Button */}
            <img src={filters} style={{cursor:"pointer"}} onClick={showDrawer} alt="filters icon" />

            {/* Custom Close Button */}
            {visible && (
                <Button
                    type="text"
                    icon={<CloseOutlined style={{ fontSize: "14px", color: "#000" }} />}
                    onClick={closeDrawer}
                    style={{
                        position: "fixed",
                        top: "16%",
                        right: "360px",
                        zIndex: 1001,
                        cursor:"pointer",
                        background: "white",
                        borderRadius: "5px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                />
            )}

            {/* Drawer Component */}
            <Drawer
                headerStyle={{ borderBottom: "none" }}
                title={
                    <div style={{ display: "flex", alignItems: "center", height: "6vh", padding: "1rem" }}>
                        <p
                            style={{
                                fontSize: "1.5vw",
                                margin: 0,
                                fontFamily: "Inter",
                                marginLeft: "0.8em",
                                fontWeight: "500",
                                color: "black",
                                flex: 1,
                                textAlign: "left",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Filters
                        </p>
                    </div>
                }
                placement="right"
                closable={false}
                onClose={closeDrawer}
                visible={visible}
                width={350}
                mask={false}
                // bodyStyle={{overflow:"scroll"}}
                style={{ marginTop: "16vh" }}
            >
                <Scrollbars
                              style={{
                                // marginLeft:'1.5px',
                                // height:"auto",
                                transition: 'height 0.3s',
                                // boxShadow:" 0px 2px 2px 0px #0000001A"
                              }}
                              autoHeight
                              autoHeightMax={"69vh"}
                              hidden={false}
                              hideTracksWhenNotNeeded={true}
                              universal
                              thumbSize={90}
                              renderView={renderView}
                              renderThumbHorizontal={renderThumb}
                              renderThumbVertical={renderThumb}
                            >
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "16px", paddingTop: 0, paddingBottom: "10px" }}>
                    {columnsData.map((column) => (
                        <div key={column.id}>
                            <span style={{ marginBottom: "8px",fontSize:"13px",fontWeight:400,fontFamily:"Inter",color:"#192228",opacity:"0.5" }}>{column.title}</span>
                            {(() => {
                                switch (column.baseReferenceId) {
                                    case "15":
                                    case "16":
                                        return (
                                            <RangePicker
                                                onChange={(dates) => handleDateRangeChange(dates, column.dataIndexWithoutIdn)}
                                                style={{ width: "100%" }}
                                            />
                                        );

                                    case "19":
                                        return (
                                            <Select
                                                className="formView"
                                                showSearch
                                                onSelect={(value) => handleSelect(value, column.dataIndexWithoutIdn)} // onSelect handler
                                                style={{ width: "100%", borderRadius: "5px" }}
                                                onSearch={(value) => onSearch(value, column.dataIndexWithoutIdn)} // Pass column.dataIndexWithoutIdn
                                                onFocus={() => focusSearch(column.dataIndexWithoutIdn)} // Pass column.dataIndexWithoutIdn
                                                allowClear
                                                filterOption={false} // Prevents AntD's default filtering
                                                loading={loading}
                                                disabled={column.isreadonly === "Y"}
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        {column.create_new_record === "Y" && column.new_record_window && (
                                                            <div
                                                                style={{
                                                                    padding: "4px",
                                                                    borderTop: "1px solid #e6e6e6",
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <PlusOutlined /> Add New {column.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            >
                                                {options?.map((option, index) => {
                                                    const { url, name } = extractUrlAndName(option.Name);
                                                    return (
                                                        <Option key={`${index}-${option.RecordID}`} value={option.RecordID}>
                                                            {url && <img src={url} alt={name} style={{ width: "20px", marginRight: "10px" }} />}
                                                            {name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        );

                                    case "17":
                                        return (
                                            <Select
                                                className="formView"
                                                disabled={column.isreadonly === "Y" ? true : false}
                                                showSearch
                                                style={{ width: "100%", borderRadius: "5px" }}
                                                onSelect={(value) => handleSelect(value, column.dataIndexWithoutIdn)}
                                                // placeholder={`Select ${column.name}`}
                                                optionFilterProp="children"
                                            // ref={formFieldIndexKey === 0 ? fousElementRef : null}
                                            >
                                                {column.listKeyAndValueArray.map((option, index) => (
                                                    <Option key={`${index}-${option.value}`} value={option.key}>
                                                        {option.value}
                                                    </Option>
                                                ))}
                                            </Select>
                                        );

                                    case "20":
                                        return (
                                            <Space direction="vertical">
                                                <Checkbox
                                                    onChange={(e) => handleCheckboxChange(e.target.checked, column.id, "y")}
                                                    checked={selectedFilters[column.id] === "y"}
                                                >
                                                    Yes
                                                </Checkbox>
                                                <Checkbox
                                                    onChange={(e) => handleCheckboxChange(e.target.checked, column.id, "n")}
                                                    checked={selectedFilters[column.id] === "n"}
                                                >
                                                    No
                                                </Checkbox>
                                            </Space>
                                        );

                                    case "10":
                                        return (
                                            <Input
                                                style={{ borderRadius: "5px" }}
                                                //   placeholder={<>Enter ${column.title}...</>}
                                                onChange={(e) => handleInputChange(e.target.value, column.dataIndexWithoutIdn)}
                                                value={selectedFilters[column.dataIndexWithoutIdn] || ""}
                                            />
                                        );

                                    default:
                                        return (
                                            <Input
                                                placeholder="Enter filter"
                                                onChange={(e) => handleInputChange(e.target.value, column.id)}
                                                // value={selectedFilters[column.id] || ""}
                                            />
                                        );
                                }
                            })()}
                        </div>
                    ))}

                    {/* Reset and Apply Buttons */}
                   
                </div>
                </Scrollbars>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px",borderTop:"0.5px solid #A5A5A5",paddingTop:"0.5rem" }}>
                        <Button style={{background:"#fff",border:"0.5px solid #A5A5A5",fontFamily:"Inter",fontWeight:700,color:"#192228",borderRadius:"5px"}} onClick={handleReset}>Reset</Button>
                        <Button type="primary"  style={{background:"#F4AC21",border:"none",fontFamily:"Inter",fontWeight:700,color:"#fff",borderRadius:"5px"}} onClick={() => {
                                setFilterArray(selectedFilters)
                                setUpsertFlag(true)
                                    console.log("Applied Filters:", selectedFilters);
                                    closeDrawer(); // Close the drawer
                                }}>
                            Apply
                        </Button>
                    </div>
            </Drawer>
        </>
    );
};

export default ViewFilters;
